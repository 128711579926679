
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import { Store } from 'vuex';
    import { IMakedocxFilter } from '@/interfaces';
    import { readMakedocxFilter, readMakedocx, readAllRecipients  } from '@/store/main/getters';
    import { dispatchUpdateMakedocxFilter, dispatchGetAllRecipients, dispatchDeleteRecipient } from '@/store/main/actions';
    

@Component
export default class SearchGoods extends Vue {
    public recipient = {title: '', name: '', gender: 'мужской', whom: '', warehouse: 'Южно-Уральской дирекции'}
    public kp_num = ''
    public kp_date = new Date().toISOString().slice(0,10).split('-').reverse().join('.')
    public tzr = 5
    
    public genders: Array = ['мужской', 'женский'];
    public xlsx_name = ''                                                   // Переменная для показа имени файла. Если не заполнена, то кнопка Создать документы недоступна.
    public loading = false    
    public dialog = false
    

    @Watch('is_ok')                                                      
    docx_created (newMsg: string, oldMsg: string) {                         // Отслеживаем название директории. Как только оно становится не null
        this.loading = false                                                // Убираем колесико с кнопки  Создать документы
        dispatchGetAllRecipients(this.$store);                              // Получаем из базы и сохраняем в хранилище список всех получателй КП
    }

    get allRecipients() {                                                   // Получаем из хранилища список всех получателй КП
        return readAllRecipients(this.$store);
    }
    
    get warehouses() {                                                      // Выковыривает из Получателей и возвращает список складов
        var whs = [];                                                   
        var all_recipients = readAllRecipients(this.$store);
        for (var i = 0; i < all_recipients.length; i++){
            if ('warehouse' in all_recipients[i]) {
                whs.push(all_recipients[i]['warehouse'])
            }
        }
        return whs;
    }
      
    get is_ok() {                                                        // Получаем из хранилища имя директории на яндекс-диске, куда создались файлы
        var is_docs_created = readMakedocx(this.$store)
        return (is_docs_created) ? true : null
    }
    
    get is_button_disabled() {                                              // Разрешает/запрещает кнопку в зависимости от заполненности полей
        return !this.recipient.title || !this.recipient.whom || !this.recipient.name || !this.kp_num || !this.kp_date || !this.tzr || !this.xlsx_name || this.loading
    }

    public async mounted() {                                                
        await dispatchGetAllRecipients(this.$store);                        // Получаем из базы и сохраняем в хранилище список всех получателй КП
    }
    
    public file_select() {                                                  // Запихиваем имя файла в переменную, чтобы показывать рядом с кнопкой загрузки файла
        this.xlsx_name = input_xlsx.files[0].name
    }

    public async delete_recipient (recipient) {
        await dispatchDeleteRecipient(this.$store, recipient);              // Удаляем получателя и актуализируем хранилице
    }   

    public async make_docx() {                                              // на кнопку "Создать документ"
        this.loading = true                                                 // Показываем колесико на кнопке Создать документы
        
        const makedocxFilter: IMakedocxFilter = {};                         // получаем шаблон фильтра
        
        makedocxFilter.title = this.recipient.title;                        // запихиваем в шаблон данные из формы
        makedocxFilter.name = this.recipient.name;
        makedocxFilter.warehouse = this.recipient.warehouse;
        makedocxFilter.gender = this.recipient.gender;
        makedocxFilter.whom = this.recipient.whom;                                    
        makedocxFilter.kp_num = this.kp_num;
        makedocxFilter.kp_date = this.kp_date;
        makedocxFilter.tzr = this.tzr;
        makedocxFilter.xlsx = input_xlsx.files[0];
        
        await dispatchUpdateMakedocxFilter(this.$store, makedocxFilter);    // Сохраняем в хранилище данные из формы
    }

}
